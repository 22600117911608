import { getPlaceFromCoordinates } from './api';
import { getCurrentUserLocation } from './browser';
import { CurrentLocationStatusEnum } from './constants';
import type { BrowserGeoLookupError, EventbritePlace } from './types';

type SuccessResponse = {
    status: CurrentLocationStatusEnum.SUCCESS;
    eventbritePlace: EventbritePlace;
    browserLocation: GeolocationPosition;
};

type BrowserErrorResponse = {
    status: CurrentLocationStatusEnum.BROWSER_ERROR;
    eventbritePlace?: never;
    browserLocation?: never;
    browserError: BrowserGeoLookupError;
};

type EBPlaceErrorResponse = {
    status: CurrentLocationStatusEnum.UNRESOLVED_EB_PLACE;
    browserLocation: GeolocationPosition;
    eventbritePlace?: never;
};

type ErrorResponse = {
    status: CurrentLocationStatusEnum.ERROR;
};

type IEventbritePlaceFromCurrentLocationResponse =
    | SuccessResponse
    | BrowserErrorResponse
    | EBPlaceErrorResponse
    | ErrorResponse;

const getEventbriteLocationFromLatLong = async (
    position: GeolocationPosition,
): Promise<IEventbritePlaceFromCurrentLocationResponse> => {
    try {
        const place = await getPlaceFromCoordinates({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
        });

        if (place === null) {
            return {
                status: CurrentLocationStatusEnum.UNRESOLVED_EB_PLACE,
                browserLocation: position,
            };
        }

        return {
            status: CurrentLocationStatusEnum.SUCCESS,
            eventbritePlace: place,
            browserLocation: position,
        };
    } catch (e) {
        return {
            status: CurrentLocationStatusEnum.UNRESOLVED_EB_PLACE,
            browserLocation: position,
        };
    }
};

/**
 * Utility that will call `getCurrentUserLocation` and leverage
 * the returned lat/lng to attempt to fetch a full EB Place Object.
 *
 * If returned `status === EBPlaceFromCurrentLocationStatusEnum.BROWSER_ERROR`
 * no lat/lng data will be returned.
 *
 * If status is equal to `EBPlaceFromCurrentLocationStatusEnum.UNRESOLVED_PLACE`
 * the browser's lat/lng will be available but no EB Place object.
 *
 * @returns GetEventbritePlaceFromCurrentUserLocationResponse
 *
 */
export const getEBPlaceFromCurrentLocation = async (
    options: PositionOptions = {},
): Promise<IEventbritePlaceFromCurrentLocationResponse> => {
    try {
        const browserLocation = await getCurrentUserLocation(options);

        if (
            browserLocation.status === CurrentLocationStatusEnum.BROWSER_ERROR
        ) {
            return {
                status: CurrentLocationStatusEnum.BROWSER_ERROR,
                browserError: browserLocation.error,
            };
        }

        const eventbritePlaceResponse: IEventbritePlaceFromCurrentLocationResponse =
            await getEventbriteLocationFromLatLong(browserLocation.position);

        return eventbritePlaceResponse;
    } catch (e) {
        return {
            status: CurrentLocationStatusEnum.ERROR,
        };
    }
};
