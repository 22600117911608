import { gettext } from '@eventbrite/i18n';
import { Button } from '@eventbrite/marmalade';
import React from 'react';
import {
    ELIGIBLE_CITIES_FOR_HALLOWEEN,
    ELIGIBLE_COUNTRIES_FOR_HALLOWEEN,
} from '../../pages/categoryBrowse/constants/seasonal';
import './SeasonalRibbon.scss';

export const SeasonalRibbon = (props: {
    isMobile?: boolean;
    locationSlug: string;
    placeId: string;
    country: string;
    browseSurface?: string;
}) => {
    const { isMobile, locationSlug, placeId, country, browseSurface } = props;

    const ctaLink = `/b/${locationSlug}/holiday/halloweenhaunt/`;
    const ribbonTitleDesktop = gettext(
        'From spooky to kooky, we’ve got just the thing. \u{1F383}',
    );
    const ribbonTitleMobile = gettext('Halloween happenings are here. ');
    const ribbonCtaTextDesktop = gettext('Find your Halloween plans.');
    const ribbonCtaTextMobile = gettext('Find your plans.');

    const isGeoEligibleForHalloween = () => {
        return (
            (country && ELIGIBLE_COUNTRIES_FOR_HALLOWEEN.includes(country)) ||
            (placeId && ELIGIBLE_CITIES_FOR_HALLOWEEN.includes(placeId))
        );
    };

    if (!isGeoEligibleForHalloween()) {
        return null;
    }

    return (
        <div className="seasonal-ribbon">
            <div className="seasonal-ribbon-container">
                <div
                    className="seasonal-ribbon-title-container"
                    data-testid="seasonal-ribbon-title"
                >
                    <h2 className="eds-text-bm">
                        {isMobile ? ribbonTitleMobile : ribbonTitleDesktop}
                    </h2>
                </div>
                <div className="seasonal-ribbon-cta">
                    <a
                        href={ctaLink || ''}
                        data-heap-id={`seasonal_ribbon_click_${browseSurface}`}
                    >
                        <Button className="seasonal-ribbon-button">
                            <span className="eds-text-bm">
                                {isMobile
                                    ? ribbonCtaTextMobile
                                    : ribbonCtaTextDesktop}
                            </span>
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SeasonalRibbon;
