import { bboxShape } from '@eventbrite/discover-utils';
import { setWindowLocation } from '@eventbrite/http';
import { gettext } from '@eventbrite/i18n';
import {
    locationDuck,
    parseLocationResponse,
    setLocationData,
    transformGeoPlaceObject,
} from '@eventbrite/redux-destination';
import { keysCamelToSnake } from '@eventbrite/transformation-utils';
import {
    CurrentLocationStatusEnum,
    getEBPlaceFromCurrentLocation,
} from '@eventbrite/user-location';
import defer from 'lodash/defer';
import { formatUrl } from 'url-lib';
import { setIsLoading } from './page';
// eslint-disable-next-line import/no-cycle
import { setNavigatorLastLocation } from '@eventbrite/personalization';
import { openCloseAnimatedDrawer } from '@eventbrite/search-utils';
import { searchByBBox, searchInformationForPlace } from './search';
import { showErrorNotification } from './ui';

const {
    actionTypes: { UPDATE_LOCATION_SUGGESTIONS },
    actions: { setWaitingForLocation, showLocationDeniedNotification },
    api: { searchLocationSuggestions: searchLocationSuggestionsApi },
} = locationDuck;

export const updateWindowLocation = (path: string) => (dispatch: Function) => {
    dispatch(setIsLoading(true));

    /* This is necessary because safari will halt all JS execution on page redirect. In
        order to ensure that the loading animation is displayed we need to force the redirect
        to the bottom of the render loop. */
    defer(() => setWindowLocation(path));
};

const updateLocationSuggestions = (suggestions: object) => ({
    type: UPDATE_LOCATION_SUGGESTIONS,
    payload: suggestions,
});

export const searchLocationSuggestions =
    (query: string) => (dispatch: Function, getState?: Function) => {
        if (!query) {
            return dispatch(updateLocationSuggestions([]));
        }

        const {
            app: { featureFlags },
        } = getState?.();

        return searchLocationSuggestionsApi(query)
            .then((response: any) =>
                dispatch(
                    updateLocationSuggestions(
                        parseLocationResponse(response, featureFlags),
                    ),
                ),
            )
            .catch(() => {
                //Fail silently as this is just for prefilling the
                //autocomplete dropdown and is run behind the scenes.
            });
    };

// 2nd to last item because of trailing slash
const isCityBrowse = () =>
    typeof window === 'object' &&
    window.location.href.split('/')[
        window.location.href.split('/').length - 2
    ] === 'events';

export const locationChange =
    ({
        slug,
        placeId,
        latitude,
        longitude,
        placeType,
        currentPlace,
        currentPlaceParent,
        isOnline = false,
        history,
    }: {
        slug?: string;
        placeId?: string;
        latitude?: number;
        longitude?: number;
        placeType?: string;
        currentPlace?: string;
        currentPlaceParent?: string;
        isOnline?: boolean;
        history?: any;
    }) =>
    (_: Function, getState: Function) => {
        let location = currentPlace;

        if (slug) {
            location = slug;
        } else if (currentPlaceParent) {
            location = `${currentPlace}, ${currentPlaceParent}`;
        }
        const locationData = {
            slug,
            placeId,
            latitude,
            longitude,
            placeType,
            currentPlace,
            currentPlaceParent,
            isOnline,
        };

        /* When the user selects "online" from the Recent suggestions the placeId is null and the slug is online,
            but the isOnline is false. Because of that we should change it to reflect the correct filter */
        if (!placeId && slug === 'online') {
            locationData.isOnline = true;
        }

        if (isCityBrowse()) {
            const { app } = getState();

            window.location.href = `${app.serverUrl}/d/${slug}/events/`;
        }

        if (!locationData.isOnline) {
            setNavigatorLastLocation({
                currentPlace,
                currentPlaceParent,
                content: currentPlace,
                secondaryContent: currentPlaceParent,
                value: `recent-${placeId}`,
                latitude,
                longitude,
                placeType,
                placeId,
                slug,
                isOnline,
                isHistorySuggestion: true,
            });
        }

        // See https://docs.google.com/document/d/1Vg-kyxM34gw71vw_5dLcfzFRhzM3scQ3FKtYUlWXLSA/edit?usp=sharing
        setLocationData(keysCamelToSnake(locationData));

        // dispatch(updateWindowLocation(formatUrl(`/d/${location}/events/`)));
        history?.push(formatUrl(`/d/${location}/events/`));
    };

export const setUserCurrentLocationAction =
    (
        history: object,
        shouldRedirect: boolean,
        place: { currentPlace?: string; bbox?: bboxShape },
        userLatLng?: GeolocationCoordinates,
    ) =>
    (dispatch: Function) => {
        const action = shouldRedirect
            ? locationChange
            : searchInformationForPlace;

        const { currentPlace, ...placeAttrs } = place;

        if (userLatLng && 'bbox' in place) {
            dispatch(
                searchInformationForPlace({
                    currentPlace,
                    ...placeAttrs,
                    isUsingCurrentLocation: true,
                    isOnline: false,
                    userLatLng,
                }),
            );
        } else if ('bbox' in place) {
            dispatch(searchByBBox(place.bbox));
        } else {
            dispatch(
                action({
                    currentPlace,
                    ...placeAttrs,
                    isUsingCurrentLocation: true,
                    isOnline: false,
                    userLatLng,
                    history,
                }),
            );
        }
    };

/*
 * Action which enacts the browser get current location api and once that promise is resolved,
 * gets a place object from the browser, then runs a search against that data.
 */
export const getUserCurrentLocation =
    (history?: object, shouldRedirect = false, includeUserLatLng = false) =>
    async (dispatch: Function, getState?: Function) => {
        const state = getState?.();
        const isAnimatedDrawerOpen = state?.ui?.isAnimatedDrawerOpen;

        dispatch(setWaitingForLocation(true));

        const currentLocation = await getEBPlaceFromCurrentLocation({
            timeout: 10000,
        });

        const success = (place: any, browserPlace: GeolocationCoordinates) => {
            dispatch(setWaitingForLocation(false));
            return dispatch(
                setUserCurrentLocationAction(
                    history || {},
                    shouldRedirect,
                    place,
                    includeUserLatLng ? browserPlace : undefined,
                ),
            );
        };

        switch (currentLocation.status) {
            case CurrentLocationStatusEnum.ERROR:
                if (isAnimatedDrawerOpen) {
                    dispatch(openCloseAnimatedDrawer(false));
                }

                dispatch(
                    showErrorNotification(
                        gettext(
                            'Unable to determine your location. Please refresh and try again.',
                        ),
                    ),
                );
                dispatch(setWaitingForLocation(false));
                return;

            case CurrentLocationStatusEnum.BROWSER_ERROR:
                if (isAnimatedDrawerOpen) {
                    dispatch(openCloseAnimatedDrawer(false));
                }

                dispatch(setWaitingForLocation(false));

                return dispatch(showLocationDeniedNotification());

            case CurrentLocationStatusEnum.UNRESOLVED_EB_PLACE:
                if (isAnimatedDrawerOpen) {
                    dispatch(openCloseAnimatedDrawer(false));
                }

                dispatch(
                    showErrorNotification(
                        gettext(
                            'Unable to change your location. Please choose another location and try again.',
                        ),
                    ),
                );
                dispatch(setWaitingForLocation(false));
                return;

            case CurrentLocationStatusEnum.SUCCESS:
                const transformedEventbritePlace = transformGeoPlaceObject(
                    currentLocation.eventbritePlace,
                );

                return success(
                    transformedEventbritePlace,
                    currentLocation.browserLocation.coords,
                );

            default:
                dispatch(setWaitingForLocation(false));
                return;
        }
    };
