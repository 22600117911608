import { withOverlayControls } from '@eventbrite/eds-structure';
import { gettext } from '@eventbrite/i18n';
import {
    getLocationSuggestions,
    LocationAutocomplete,
} from '@eventbrite/location-autocomplete';
import { connect } from 'react-redux';
import { searchLocationSuggestions as searchLocationSuggestionsAction } from '../../redux/actions/location';
import { DiscoverState } from '../../types';

export interface OwnLocationAutocompleteProps {
    hideOnline?: boolean;
}

const _mapStateToProps = (
    state: DiscoverState,
    ownProps: OwnLocationAutocompleteProps,
) => ({
    currentPlace:
        ownProps.hideOnline && state.location.isOnline
            ? ''
            : state.location.currentPlace,
    currentPlaceParent: state.location.currentPlaceParent,
    placeholder: gettext('Choose a location'),
    isBBoxSearch: !!state.search.eventSearch.bbox,
    isUseCurrentLocationEnabled: state.app.featureFlags.useCurrentLocation,
    isUsingCurrentLocation: state.location.isUsingCurrentLocation,
    isWaitingForLocation: state.location.isWaitingForLocation,
    locationSuggestions: getLocationSuggestions(
        state.location,
        state.app.featureFlags.useCurrentLocation,
    ),
    placeId: state.location.placeId,
    googleMapsApiKey: state.googleMapsApiKey,
    latitude: state.location.latitude,
    longitude: state.location.longitude,
    isOnline: state.location.isOnline,
});

const _mapDispatchToProps = {
    handleLocationSuggestionChange: searchLocationSuggestionsAction,
};

export default withOverlayControls<any>(
    connect(_mapStateToProps, _mapDispatchToProps)(LocationAutocomplete),
);
