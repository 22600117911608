import { sdkRequest } from '@eventbrite/http';
import type { EventbritePlace } from './types';

export interface IAPIPlaceResponse {
    place: EventbritePlace;
}

const GET_PLACE_FROM_COORDINATES_URL = '/api/v3/geo/place_from_coordinates/';

interface IGetPlaceFromCoordinates {
    latitude: number;
    longitude: number;
}

export const getPlaceFromCoordinates = async ({
    latitude,
    longitude,
}: IGetPlaceFromCoordinates): Promise<EventbritePlace | null> => {
    const placeResponse: IAPIPlaceResponse = await sdkRequest(
        `${GET_PLACE_FROM_COORDINATES_URL}?latitude=${latitude}&longitude=${longitude}`,
    );

    if (Object.keys(placeResponse).length === 0) {
        return null;
    }

    const place: EventbritePlace = placeResponse.place;
    if (Object.keys(place).length === 0) {
        return null;
    }
    return place;
};
