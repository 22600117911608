import {
    FESTIVAL_EVENTBRITE_FORMAT,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    HOBBIES_EVENTBRITE_CATEGORY,
    MUSIC_EVENTBRITE_CATEGORY,
    PARTY_EVENTBRITE_FORMAT,
    PERFORMING_EVENTBRITE_CATEGORY,
} from '@eventbrite/categories';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { OrganicEventSearchFiltersApiPayload } from '../../../api/searchOrganicEvents';
export const SPD_HEADER_DESKTOP = '';
export const SPD_HEADER_MOBILE = '';
export const SPD_HEADER_TITLE = gettext("St. Patrick's day your way \u{1F340}");

export interface BucketTile {
    key: string;
    name: GenericLazyString;
}

export const SEASONAL_TAGS = [
    MUSIC_EVENTBRITE_CATEGORY,
    FOOD_AND_DRINK_EVENTBRITE_CATEGORY,
    HOBBIES_EVENTBRITE_CATEGORY,
    PERFORMING_EVENTBRITE_CATEGORY,
];

export const SeasonalTabs: { [key: string]: BucketTile[] } = {
    VALENTINE: [
        {
            key: 'All',
            name: gettext('Celebrate Love'),
        },
        {
            key: 'Music',
            name: gettext('Groove to the beat'),
        },
        {
            key: 'FoodAndDrinks',
            name: gettext('Love-filled eats'),
        },
        {
            key: 'HobbiesAndArts',
            name: gettext('Heartfelt hobbies'),
        },
    ],
    SPD: [
        {
            key: 'BarCrawl',
            name: gettext('Bar Crawls'),
        },
        {
            key: 'Parties',
            name: gettext('Parties'),
        },
        {
            key: 'FestiveFun',
            name: gettext('Festive Fun'),
        },
    ],
};

export const SeasonalTabsSearchQuery: {
    [key: string]: OrganicEventSearchFiltersApiPayload;
} = {
    All: {
        tags: [...SEASONAL_TAGS],
    },
    Music: {
        tags: [MUSIC_EVENTBRITE_CATEGORY],
    },
    FoodAndDrinks: {
        tags: [FOOD_AND_DRINK_EVENTBRITE_CATEGORY],
    },
    HobbiesAndArts: {
        tags: [HOBBIES_EVENTBRITE_CATEGORY, PERFORMING_EVENTBRITE_CATEGORY],
    },
    BarCrawl: {
        q: 'crawl',
        tags: ['EventbriteSubCategory/16001'],
    },
    Parties: {
        q: "Patrick's Day shuttle cruise boat",
        tags: [PARTY_EVENTBRITE_FORMAT],
    },
    FestiveFun: {
        q: undefined,
        tags: [
            'EventbriteSubCategory/16001',
            'EventbriteSubCategory/10003',
            FESTIVAL_EVENTBRITE_FORMAT,
        ],
    },
};

export const ELIGIBLE_CITIES_FOR_SEASONAL_MODULE = {
    '101750367': 'LONDON',
    '101735835': 'TORONTO',
    '101933229': 'MELBOURNE',
    '101751737': 'DUBLIN',
    '101932003': 'SYDNEY',
};

export const DEFAULT_TAB_FOR_SEASONAL: { [key: string]: string } = {
    VALENTINE: SeasonalTabs['VALENTINE'][0].key,
    SPD: SeasonalTabs['SPD'][0].key,
};

export const BUCKET_KEY_TO_URL_PARAM: { [key: string]: string } = {
    All: 'celebrate-love',
    Music: 'groove-to-the-beat',
    FoodAndDrinks: 'love-filled-eats',
    HobbiesAndArts: 'heartfelt-hobbies',
    BarCrawl: 'bar-crawls',
    Parties: 'parties',
    FestiveFun: 'festive-fun',
};

export const BUCKET_URL_PARAM_TO_KEY: { [key: string]: string } = {
    'celebrate-love': 'All',
    'groove-to-the-beat': 'Music',
    'love-filled-eats': 'FoodAndDrinks',
    'heartfelt-hobbies': 'HobbiesAndArts',
    'bar-crawls': 'BarCrawl',
    parties: 'Parties',
    'festive-fun': 'FestiveFun',
};

export const BUCKET_URL_PARAM_TO_AFFCODE_MAPPING: { [key: string]: string } = {
    'celebrate-love': 'all',
    'groove-to-the-beat': 'music',
    'love-filled-eats': 'food',
    'heartfelt-hobbies': 'hobbiesarts',
    'bar-crawls': 'barcrawls',
    parties: 'parties',
    'festive-fun': 'festivefun',
};

export const SEASON_TO_AFFCODE_MAPPING_CURATED_MODULE: {
    [key: string]: string;
} = {
    VALENTINE: 'ebdssqvalentinescurated',
    SPD: 'ebdssqspdcurated',
};

export const THEMATIC_PAGE_ELIGIBLE_CATEGORIES = [
    'lgbt',
    'dating',
    'independence-day',
    'halloweenhaunt',
];

export const ELIGIBLE_COUNTRIES_FOR_HALLOWEEN = ['United States', 'USA'];

export const ELIGIBLE_CITIES_FOR_HALLOWEEN = [
    '101750367', //London
    '101933229', //Melbourne
    '101932003', //Sydney
];
