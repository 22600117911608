import { gettext } from '@eventbrite/i18n';

export enum CurrentLocationStatusEnum {
    SUCCESS = 'success',
    ERROR = 'error',
    UNRESOLVED_EB_PLACE = 'unresolvedEventbritePlaceObject',
    BROWSER_ERROR = 'browserError',
}

export enum GeolocationPositionErrorEnum {
    PERMISSION_DENIED = 1,
    POSITION_UNAVAILABLE = 2,
    TIMEOUT = 3,
    NO_BROWSER_SUPPORT = 4,
}

/**
 * User friendly strings for handling GeolocationPositionError as the
 * built in messaging is lacking.
 */
export const GeolocationPositionErrorMessageMap: Record<number, string> = {
    [GeolocationPositionErrorEnum.PERMISSION_DENIED]: gettext(
        'Site does not have permission to use browser location',
    ).toString(),
    [GeolocationPositionErrorEnum.POSITION_UNAVAILABLE]: gettext(
        'Browser unable to determine current location',
    ).toString(),
    [GeolocationPositionErrorEnum.TIMEOUT]: gettext(
        'Browser took too long to find your location',
    ).toString(),
    [GeolocationPositionErrorEnum.NO_BROWSER_SUPPORT]: gettext(
        'Browser does not support location services',
    ).toString(),
};
