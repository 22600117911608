import { HAS_WINDOW } from '@eventbrite/feature-detection';
import {
    CurrentLocationStatusEnum,
    GeolocationPositionErrorEnum,
} from './constants';
import { BrowserGeoLookupError } from './types';

type BrowserStatusSuccess = {
    status: CurrentLocationStatusEnum.SUCCESS;
    position: GeolocationPosition;
};
type BrowserStatusError = {
    status: CurrentLocationStatusEnum.BROWSER_ERROR;
    position: null;
    error: BrowserGeoLookupError;
};
type IGetBrowserLocation = BrowserStatusSuccess | BrowserStatusError;

/**
 * Utility that will attempt to access the navigator
 * object off of the window to determine a best guess
 * of the user's current location.
 *
 * The method in use here will prioritize speed over accuracy.
 * https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API
 *
 * @param options Configuration object.
 * ```
 * //Manual timeout length to short circuit lookup. Defaults to 6000
 * manualTimeoutLength: number
 * ```
 *
 * @returns IGetBrowserLocation
 * ```
 *  //End state of the location lookup
 *  status: BrowserLocationStatus.ERROR | BrowserLocationStatus.SUCCESS;
 *  //lat/long of current location or null in case of error
 *  position: {latitude: number; longitude: number} | null;
 *  //Raw error object from browser lookup
 *  //https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
 *  error?: GeolocationPositionError;
 *  //User friendly string translated from error code in GeolocationPositionError
 *  errorMessage?: string;
 *```
 */
export const getCurrentUserLocation = async (
    options: PositionOptions = {},
): Promise<IGetBrowserLocation> => {
    if (
        !HAS_WINDOW ||
        !window.navigator ||
        !('geolocation' in window.navigator)
    ) {
        return new Promise((resolve) =>
            resolve({
                status: CurrentLocationStatusEnum.BROWSER_ERROR,
                error: {
                    code: GeolocationPositionErrorEnum.NO_BROWSER_SUPPORT,
                },
                position: null,
            }),
        );
    }

    const locationPromise: Promise<GeolocationPosition> = new Promise(
        (resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        },
    );

    try {
        const position = await locationPromise;
        const response: IGetBrowserLocation = {
            status: CurrentLocationStatusEnum.SUCCESS,
            position: position,
        };
        return response;
    } catch (e: unknown) {
        if (e instanceof GeolocationPositionError) {
            return {
                status: CurrentLocationStatusEnum.BROWSER_ERROR,
                position: null,
                error: e,
            };
        }

        throw e;
    }
};
